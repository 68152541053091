import { Injectable } from '@angular/core';
import { SocketPresenceUser } from '@core/_enums/socket-presence-user.enum';
import { environment } from '@environment/environment';
import { Socket, io } from 'socket.io-client';

@Injectable({
    providedIn: 'root',
})
export class SocketPresenceUserService {
    private socket: Socket | undefined;

    constructor() {}

    public get socketRef(): Socket | undefined {
        return this.socket;
    }

    init(Authorization: string) {
        console.log('🚀🚀🚀 ~ SocketUserPresenceService ~ init ~ init');
        console.log(
            '🚀 ~ SocketPresenceUserService ~ init ~  this.socketREF:',
            this.socket
        );

        this.socket = io(environment.socketUrl, {
            // autoConnect: false,
            reconnectionDelay: 10000, // defaults to 1000
            reconnectionDelayMax: 10000, // defaults to 5000
            extraHeaders: {
                Authorization,
            },
        });

        // this.socket.connect();

        console.log(
            '🚀 ~ SocketPresenceUserService ~ init ~  this.socketConnected:',
            this.socket?.connected
        );

        this.socket.on(SocketPresenceUser.CONNECT, () => {
            console.log(
                '🚀🚀🚀 ~ SocketUserPresenceService ~ this.socket.on ~ connected',
                this.socket?.connected
            );

            // [ ]: Its Needed? -> check if Im in the ROute of negotiation Settlement and joiningAndListenDisputeRoom again
        });

        this.socket.on(SocketPresenceUser.DISCONNECT, (reason) => {
            console.log(
                '🛑🛑🛑 ~ SocketUserPresenceService ~ this.socket.on ~ disconnect:',
                reason
            );
            console.log(this.socket?.connected); // false
        });

        this.socket.on(SocketPresenceUser.RECONNECT, (attempt) => {
            console.log(
                '🚀 ~ SocketPresenceUserService ~ this.socket.on ~ attempt:',
                attempt
            );
        });

        console.log(
            '🚀 ~ SocketPresenceUserService ~ init ~ this.socket:',
            this.socket
        );
    }

    disconnect(userId: number | undefined) {
        if (!this.socket) return;

        this.socket.emit(SocketPresenceUser.EMIT_LOGOUT_MANUALLY, {
            id: userId,
        });
        this.socket.disconnect();
        this.socket.removeAllListeners();
    }

    joiningAndListenDisputeRoom(caseId: number, authorization: string) {
        if (!this.socket || !this.socket.connected) {
            // this.init(authorization);
            this.joiningAndListenDisputeRoom(caseId, authorization);
            return;
        }

        this.socket.emit(SocketPresenceUser.EMIT_JOINING_DISPUTE, {
            caseId,
        });

        // This its for listen by Dispute
        /*this.socket.on(
            `${SocketPresenceUser.LISTEN_DISPUTE_BY_ID}${caseId}`,
            (users) => {
                console.log(
                    '🚀🚀🚀 ~ SocketPresenceUserService ~ this.socket.on ~ LISTEN_DISPUTE_BY_ID:',
                    users
                );
            }
        ); */
    }

    disconnectFromDisputeRoom(caseId: number) {
        if (!this.socket || !this.socket.connected) return;

        this.socket.emit(SocketPresenceUser.EMIT_DISCONNECTION_DIPUSTE, {
            caseId,
        });

        this.socket.removeAllListeners(
            `${SocketPresenceUser.LISTEN_DISPUTE_BY_ID}${caseId}`
        );
    }
}

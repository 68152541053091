import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
    KeyOAuthProvider,
    OAuthProviders,
    StatusOAuthSync,
} from '@core/_enums/oauth-providers-enum';
import { Permissions, Roles } from '@core/_enums/roles.enum';
import { StateSocialSync } from '@core/models/oauth-providers.types';
import { ChatSettingsService } from '@core/services/data-access/chat-settings.service';
import { SocketPresenceUserService } from '@core/services/data-access/socket-presence-user.service';
import { AuthService } from '@module-auth/data-access/auth.service';
import { BehaviorSubject } from 'rxjs';
import {
    User,
    UserAssociatedToRepresentative,
    UserData,
} from 'src/app/_core/models/user.model';
import { StorageService } from '../utils/storage.service';

@Injectable({
    providedIn: 'root',
})
export class UserStoreService {
    //BehaviorSubject
    private user$: BehaviorSubject<User | null> =
        new BehaviorSubject<User | null>(null);

    private stateOAuthSync$: BehaviorSubject<StateSocialSync> =
        new BehaviorSubject<StateSocialSync>({
            origin: null,
            originKey: null,
            status: StatusOAuthSync.NOT_SYNC,
        });

    private authService = inject(AuthService);
    private storageService = inject(StorageService);
    private router = inject(Router);
    private chatSettingsService = inject(ChatSettingsService);
    private socketPresenceUser = inject(SocketPresenceUserService);

    constructor() {
        this.checkLoadUserData();
    }

    ///////////////////////////// User ///////////////////////////////////
    public get userSession(): User | null {
        return this.user$.value;
    }

    public get userStream() {
        return this.user$.asObservable();
    }

    public set updateUserState(newUserInfo: User) {
        // LOAD LATEST STATE OAUTH SYNC WHEN RECIEVED FROM BACKEND
        this.loadOAuthStateSync(newUserInfo.user);
        this.user$.next(newUserInfo);
    }

    public get stateOAuthSyncStream() {
        return this.stateOAuthSync$.asObservable();
    }

    public get stateOAuthSync() {
        return this.stateOAuthSync$.value;
    }

    public set statusOAuthGoogle(newStatusState: StatusOAuthSync) {
        this.stateOAuthSync$.next({
            origin: OAuthProviders.GOOGLE,
            originKey: KeyOAuthProvider.GOOGLE,
            status: newStatusState,
        });
    }

    public set statusOAuthMicrosoft(newStatusState: StatusOAuthSync) {
        this.stateOAuthSync$.next({
            origin: OAuthProviders.MICROSOFT,
            originKey: KeyOAuthProvider.MICROSOFT,
            status: newStatusState,
        });
    }

    public resetStateOAuth() {
        this.stateOAuthSync$.next({
            origin: null,
            originKey: null,
            status: StatusOAuthSync.NOT_SYNC,
        });
    }

    updateOnlyUserInfo(newUserInfo: UserData) {
        const userUpdated: User = {
            roles: this.user$.value?.roles as Roles[],
            token: this.user$.value?.token as string,
            user: { ...this.user$.value?.user, ...newUserInfo } as UserData,
            permissions: this.user$.value?.permissions as Permissions[],
        };
        console.log(
            '🚀 ~ UserStoreService ~ setupdateOnlyUserInfo ~ userUpdated:',
            userUpdated
        );
        this.updateUserState = userUpdated;
        this.storageService.validateAndSaveOnlyUserOnStorage(userUpdated.user);
    }

    updateOnlyUserAssociatedTo(
        newUserInfoAssociatedTo: UserAssociatedToRepresentative
    ) {
        const userUpdated: User = {
            roles: this.user$.value?.roles as Roles[],
            token: this.user$.value?.token as string,
            user: this.user$.value?.user as UserData,
            permissions: this.user$.value?.permissions as Permissions[],
            associatedTo: {
                ...this.user$.value?.associatedTo,
                ...newUserInfoAssociatedTo,
            } as UserData,
        };
        console.log(
            '🚀 ~ UserStoreService ~ setupdateOnlyUserAssociatedTo ~ userUpdated:',
            userUpdated
        );
        this.updateUserState = userUpdated;
        this.storageService.validateAndSaveUserWithAssociatedOnStorage(
            userUpdated
        );
    }

    logout(dataLogout = { onlyLocalLogout: false }): void {
        if (!dataLogout.onlyLocalLogout) this.authService.processAsyncLogout();

        this.socketPresenceUser.disconnect(
            this.user$.value?.user.id ?? undefined
        );
        this.storageService.clearUser();
        this.resetStateOAuth();
        this.user$.next(null);
        this.router.navigate(['/auth/login'], { replaceUrl: true });
        this.chatSettingsService.logout();
    }

    private async checkLoadUserData() {
        console.log('== Loading/checking Current User Session ==');

        console.log('== Checking Remember Storage ==');
        const rememberMe = this.storageService.getLocal('remember-me');
        console.log('🚀 ~~ rememberMe: ', rememberMe);

        console.log('== Loading Storage ==');

        let userOnStorage: User;
        rememberMe
            ? (userOnStorage = this.storageService.getLocal('user'))
            : (userOnStorage = this.storageService.getSession('user'));

        console.log('From => ', rememberMe ? 'local' : 'session');
        console.log('== Storage#CurrentUser =>', userOnStorage);
        if (userOnStorage && userOnStorage.user) {
            // LOAD LATEST STATE OAUTH SYNC WHEN RECIEVED FROM BACKEND
            // For NOW only ONE PROVIDER AT TIME
            this.updateUserState = userOnStorage;
        } else {
            this.user$.next(null);
        }
    }

    private loadOAuthStateSync(userData: UserData) {
        if (userData.providers && userData.providers[0]) {
            const originOAuthProvider =
                userData.providers[0].provider.key === KeyOAuthProvider.GOOGLE
                    ? OAuthProviders.GOOGLE
                    : userData.providers[0].provider.key ===
                      KeyOAuthProvider.MICROSOFT
                    ? OAuthProviders.MICROSOFT
                    : null;

            /*   console.log(
                '🚀 ~ UserStoreService ~ loadOAuthStateSync ~ userData.providers[0].scopes:',
                userData.providers[0].scopes.toString()
            ); */

            // Recieved in .scopes like array parsing to string to search with includes
            const scopesGrantedByUser = userData.providers[0].scopes.toString();

            // SCOPES FROM PROVIDERS 'calendar.events' = GOOGLE || 'Calendars.ReadWrite = MICROSOFT
            const statusOAuthProvider =
                scopesGrantedByUser.includes('calendar.events') ||
                scopesGrantedByUser.includes('Calendars.ReadWrite')
                    ? StatusOAuthSync.SYNCED
                    : StatusOAuthSync.PROBLEM_EXIST;

            // UPDATING STATEOAUTH WITH DATA FROM BACKEND AFTER VERIFICATIONS
            this.stateOAuthSync$.next({
                origin: originOAuthProvider,
                originKey: userData.providers[0].provider.key,
                status: statusOAuthProvider,
                emailProvider: userData.providers[0].external_provider_email,
            });
        } else {
            this.resetStateOAuth();
        }
    }
}

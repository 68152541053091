<div class="layout-topbar">
    <a class="app-logo" routerLink="/">
        <img alt="app logo" [src]="'assets/layout/images/logo-white.svg'" />
        <span class="app-name"></span>
    </a>

    <button
        #menubutton
        class="topbar-menubutton p-link"
        type="button"
        (click)="onMenuButtonClick()"
    >
        <span></span>
    </button>

    <ul class="topbar-menu">
        <li *ngFor="let item of tabs; let i = index">
            <a
                [routerLink]="item.routerLink"
                routerLinkActive="active-route"
                [routerLinkActiveOptions]="
                    item.routerLinkActiveOptions || {
                        paths: 'exact',
                        queryParams: 'ignored',
                        fragment: 'ignored',
                        matrixParams: 'ignored'
                    }
                "
                [fragment]="item.fragment"
                [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment!"
                [skipLocationChange]="item.skipLocationChange!"
                [replaceUrl]="item.replaceUrl!"
                [state]="item.state"
                [queryParams]="item.queryParams"
            >
                <span>{{ item.label }}</span>
            </a>
            <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
        </li>
        <!--  <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty">
            Use (cmd + click) to open a tab
        </li> -->
    </ul>

    <!-- TOP BAR SEARCH -->
    <!--  <div
        class="topbar-search"
        [ngClass]="{ 'topbar-search-active': searchActive }"
    >
        <button
            class="topbar-searchbutton p-link"
            type="button"
            (click)="activateSearch()"
        >
            <i class="pi pi-search"></i>
        </button>

        <div class="search-input-wrapper">
            <span class="p-input-icon-right">
                <input
                    #searchinput
                    type="text"
                    pInputText
                    placeholder="Search"
                    (blur)="deactivateSearch()"
                    (keydown.escape)="deactivateSearch()"
                />
                <i class="pi pi-search"></i>
            </span>
        </div>
    </div> -->

    <!-- TOP BAR PROFILE -->
    <div class="topbar-profile ml-auto md:ml-auto lg:ml-5">
        <!--   [hideOnOutsideClick]="true" -->
        <button
            *ngIf="userStore.userStream | async as userSession"
            class="topbar-profile-button p-link cursor-auto"
            type="button"
            pStyleClass="@next"
            enterClass="hidden"
            enterActiveClass="scalein"
            leaveToClass="hidden"
            leaveActiveClass="fadeout"
            [disabled]="true"
        >
            <span class="profile-details text-right">
                <span class="profile-name">
                    <span
                        *ngIf="
                            userSession.user.companies &&
                            userSession.user.companies.length > 0
                        "
                    >
                        {{ userSession.user.companies[0].name }}
                        <br />
                    </span>

                    <span
                        *ngIf="
                            userSession.user.claimant_branch_user &&
                            userSession.user.claimant_branch_user.branch &&
                            userSession.user.claimant_branch_user.branch
                                .name_branch
                        "
                    >
                        {{
                            userSession.user.claimant_branch_user.branch
                                .name_branch
                        }}
                        <br />
                    </span>

                    <span *ngIf="userSession.user.law_firm">
                        {{ userSession.user.law_firm }}
                        <br />
                    </span>

                    {{ userSession.user.name }}
                    {{ userSession.user.last_name }}</span
                >

                <!-- <span class="profile-job"
                    ><span
                        class="profile-job text-base font-italic"
                        *ngIf="userSession.associatedTo"
                        >{{
                            userSession.associatedTo.roles[0].name | titlecase
                        }}
                        - </span
                    >{{ userSession.roles[0] | RolePipe }}</span
                > -->
            </span>

            <ng-container
                *ngIf="userSession.user.file; else showAvatarFallBack"
            >
                <p-avatar
                    class="ml-2"
                    [image]="userSession.user.file.absolute_url"
                    shape="circle"
                    size="large"
                ></p-avatar>
            </ng-container>
            <ng-template #showAvatarFallBack>
                <p-avatar
                    class="ml-2"
                    icon="pi pi-user"
                    shape="circle"
                ></p-avatar>
            </ng-template>
            <!-- <i class="pi pi-angle-down"></i> -->
        </button>
        <!--  <ul
            class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto"
        >
            <li>
                <a
                    pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                >
                    <i class="pi pi-user mr-3"></i>
                    <span>Profile</span>
                </a>
                <a
                    pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                >
                    <i class="pi pi-inbox mr-3"></i>
                    <span>Inbox</span>
                </a>
                <a
                    pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
                >
                    <i class="pi pi-cog mr-3"></i>
                    <span>Settings</span>
                </a>
                <a
                    pRipple
                    class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer text-color"
                    routerLink="/auth/login"
                    [replaceUrl]="true"
                >
                    <i class="pi pi-power-off mr-3"></i>
                    <span>Logout</span>
                </a>
            </li>
        </ul> -->
    </div>
</div>

export enum Roles {
    SuperAdmin = 'SUPER-ADMIN',
    Admin = 'ADMIN',
    Claimant = 'CLAIMANT',
    Respondent = 'RESPONDENT',
    SalesRepresentative = 'SALES-REPRESENTATIVE',
    BranchManager = 'BRANCH-MANAGER',
    Client = 'CLIENT',
    Arbitrator = 'ARBITRATOR',
    LegalRepresentative = 'REPRESENTATIVE',
    Undefined = 'UNDEFINED',
    ViewOnlyUser = 'VIEW-ONLY-USER',
    CaseManagerUser = 'CASE-MANAGER-USER',
}

export enum RoleByLevel {
    Claimant = 1,
    BranchManager = 2,
    CaseManagerUser = 3,
    ViewOnlyUser = 4,
}

export enum RoleName {
    Claimant = 'Claimant',
    BranchManager = 'Branch Manager',
    CaseManagerUser = 'Case Manager User',
    ViewOnlyUser = 'View Only User',
    LegalRepresentative = 'Representative',
}

export enum RoleKey {
    CaseManagerUser = 'ROLE_CASE_MANAGER',
    ViewOnlyUser = 'ROLE_VIEW_ONLY',
}

export enum Permissions {
    PERMISSION_VIRTUAL_CHAT_ROOM = 'PERMISSION_VIRTUAL_CHAT_ROOM',
    PERMISSION_MAKE_OFFERS_AND_COUNTEROFFERS = 'PERMISSION_MAKE_OFFERS_AND_COUNTEROFFERS',
    PERMISSION_CREATE_SIGN_SETTLEMENT = 'PERMISSION_CREATE_SIGN_SETTLEMENT',
    PERMISSION_DOWNLOAD_FILES = 'PERMISSION_DOWNLOAD_FILES',
}

export enum PermissionsName {
    PERMISSION_VIRTUAL_CHAT_ROOM = 'PERMISSION VIRTUAL CHAT ROOM',
    PERMISSION_MAKE_OFFERS_AND_COUNTEROFFERS = 'PERMISSION MAKE OFFERS AND COUNTEROFFERS',
    PERMISSION_CREATE_SIGN_SETTLEMENT = 'PERMISSION CREATE SIGN SETTLEMENT',
    PERMISSION_DOWNLOAD_FILES = 'PERMISSION DOWNLOAD FILES',
}

import { Pipe, PipeTransform } from '@angular/core';
import { RoleName, Roles } from '@core/_enums/roles.enum';

@Pipe({
    name: 'RolePipe',
    standalone: true,
})
export class RoleNamePipe implements PipeTransform {
    readonly Roles = Roles;
    readonly RoleName = RoleName;

    transform(value: string): string | null {
        switch (value) {
            case Roles.Claimant:
                return RoleName.Claimant;
            case Roles.LegalRepresentative:
                return RoleName.LegalRepresentative;
            case Roles.CaseManagerUser:
                return RoleName.CaseManagerUser;
            case Roles.ViewOnlyUser:
                return RoleName.ViewOnlyUser;
            case Roles.BranchManager:
                return RoleName.BranchManager;

            default:
                return null;
        }
    }
}
